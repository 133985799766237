import styled from "@emotion/styled";

const DevelopersCardsSectionWrapper = styled.section`
  overflow: hidden;
  .wrapper-parent {
    min-height: 510px;
  }
`;

export { DevelopersCardsSectionWrapper };
