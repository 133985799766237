import React, { FC, useState } from "react";
import TopText from "../../topText/topText";
import ContactForm from "../../contactForm/contactForm";
import SuccessForm from "../../successForm/successForm";
import BlueSection from "../../blueSetion/blueSection";
import DevelopersTopSection from "../developersTopSection/developersTopSection";
import DevelopersCardsSection from "../developersCardsSection/developersCardsSection";
import { DevelopersWrapper } from "./developersSection.styles";
interface Props {
  //   title: string;
}
const DevelopersSection: FC<Props> = () => {
  const topTextData = {
    title: "Need support?",
    subtitle:
      "Integrate our web JavaScript and mobile SDK in as little as one day. Supports web, iOS, and Android.",
  };
  const blueData = {
    textArr: ["Move money", " fast, ", "without risk."],
    text: "Book a demo",
  };
  const [showForm, setShowForm] = useState(true);
  return (
    <DevelopersWrapper className="font-Inter section-wrapper">
      <DevelopersTopSection />
      <DevelopersCardsSection />
      <div className="font-Inter layout-width relative flex flex-row justify-between pt-20 md:flex-col  md:mb-28 md:pt-0">
        <div className="overlays absolute top-0 left-0 block"></div>
        <div className="overlay1 absolute top-0 left-0 block"></div>
        <div className="overlay2 absolute top-full left-0 block"></div>
        <TopText title={topTextData.title} subtitle={topTextData.subtitle} />
        {showForm ? (
          <ContactForm formName={"support"} setShowForm={setShowForm} />
        ) : (
          <SuccessForm />
        )}
      </div>
      <BlueSection textArr={blueData.textArr} btnText={blueData.text} />
    </DevelopersWrapper>
  );
};

export default DevelopersSection;
