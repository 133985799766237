import { css } from "@emotion/react";
import developers from "../../../images/developers_page.svg";
import media from "../../breakpoints";

const DevelopersTopSectionWrapper = css`
  overflow: hidden;
  padding-top: 41px;
  .wrapper-parent {
    min-height: 580px;
  }
  .curves-parent-top {
    background: url(${developers}) center/contain no-repeat;
    width: 1865px;
    height: 622px;
    transform: translate(-23%, 0);
  }
  .image-parent img {
    max-width: 590px;
    width: 100%;
  }
  .title-parent {
    max-width: 488px;
    font-size: 64px;
    line-height: 78px;
    font-weight: 600;
    ${media("md")} {
      font-size: 34px;
      line-height: 44px;
      font-weight: 600;
    }
  }
  .subtitle-parent {
    max-width: 488px;
  }
  .btn-parent {
    max-width: 211px;
  }
`;

export { DevelopersTopSectionWrapper };
