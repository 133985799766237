import styled from "@emotion/styled";


const CardWrapper = styled.div`
  .link-parent:hover,
  .link-parent:focus {
    box-shadow: 0px 20px 20px rgba(13, 40, 59, 0.2);
  }
  
`;
export { CardWrapper };
