import React, { FC } from "react";
import { Link } from "gatsby";
import { DevelopersTopSectionWrapper } from "./developersTopSection.styles";
import developersCover from "../../../images/home_developers_bg.png";
interface Props {
  //   title: string;
}
const DevelopersTopSection: FC<Props> = () => {
  return (
    <section
      className="section-wrapper relative"
      css={DevelopersTopSectionWrapper}
    >
      <div className="curves-parent-top absolute top-0 left-0"></div>
      <div className="wrapper-parent layout-width relative flex flex-col flex-nowrap justify-start w-full pt-4">
        <div className="flex flex-row justify-between w-full md:flex-col">
          <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col justify-center mb-20">
              <p className="title-parent text-blue-darken text-6xl font-bold pt-10 mb-10 md:text-4xl md:text-left md:tracking-tighter md:mb-6 md:mr-auto">
                Sardine API Documentation
              </p>
              <p className="subtitle-parent text-blue-darken text-lg font-normal mb-10 md:tracking-tight md:text-left md:text-lg md:font-normal md:mb-10">
                With just one <span className="font-medium">API</span>{" "}
                integration you get{" "}
                <span className="font-medium">payments</span>,{" "}
                <span className="font-medium">fraud</span> and{" "}
                <span className="font-medium">compliance</span>.
              </p>
              <div className="flex flex-row justify-start">
                <button className="btn-parent bg-blue-accent h-12 text-white text-center text-base font-semibold ml-0 mr-20 px-8 rounded-2xl md:mr-5 hover:bg-blue-hover focus:bg-blue-darken">
                  <a className="no-underline" href="https://docs.sardine.ai/">
                    Documentation
                  </a>
                </button>
                <Link
                  to="/contact"
                  className="font-semibold text-blue-accent text-base content-start py-3 md:whitespace-nowrap"
                >
                  Request Access
                </Link>
              </div>
            </div>
          </div>
          <div className="image-parent flex flex-col justify-center mx-auto">
            <img src={developersCover} alt="developers" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DevelopersTopSection;
