import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo/seo";
import DevelopersSection from "../components/developersSections/developersSection/developersSection";

const DevelopersPage = () => {
  return (
    <Layout>
      <Seo title="Developers" pathname="/developers" />
      <main>
        <DevelopersSection />
      </main>
    </Layout>
  );
};

export default DevelopersPage;
