import React, { FC } from "react";
import { Link } from "gatsby";
import { CardWrapper } from "./card.styles";
import { css } from "@emotion/react";
import arrow from "../../images/developers/developers_arrow.svg";
import media from "../breakpoints";

interface Props {
  img: string;
  title: string;
  url: string;
  type?: string;
}
const Card: FC<Props> = ({ img, title, url, type }) => {
  return (
    <CardWrapper className="m-4 md:my-2.5">
      <Link
        to={url}
        className="link-parent bg-white flex flex-col justify-between rounded-2xl"
        css={css`
          width: 100%;
          height: ${type === "large" ? "280px" : "124px"};
          min-width: 280px;
          max-width: 280px;
          ${media("md")} {
            max-width: 328px;
            min-width: 328px;
          }
        `}
      >
        <img
          className="arrow"
          css={css`
            width: ${type === "large" ? "100%" : "36px"};
            height: auto;
            margin: ${type === "large" ? "0" : "16px 16px 0"};
          `}
          src={img}
          alt="arrow"
        />
        <div
          className={`flex ${
            type === "large" ? "flex-col" : "flex-row"
          } justify-between flex-1 p-4`}
        >
          <p className="title font-Inter relative text-blue-darken text-lg font-semibold leading-6 m-1.5">
            {title}
          </p>
          <img
            className="arrow mt-auto"
            src={arrow}
            width={24}
            height={24}
            alt="arrow"
          />
        </div>
      </Link>
    </CardWrapper>
  );
};

export default Card;
