import React, { FC } from "react";
import { DevelopersCardsSectionWrapper } from "./developersCardsSection.styles";
import Card from "../../card/card";
import largImage from "../../../images/developers/developers_lg.svg";
import lines from "../../../images/developers/developers_lines.svg";

interface Props {
  //   title: string;
}
const DevelopersCardsSection: FC<Props> = () => {
  const cardsItems = [
    {
      img: largImage,
      title: "Try Sardine APIs",
      url: "https://docs.sardine.ai/try_api_online/",
      type: "large",
    },
    {
      img: largImage,
      title: "Behavior Biometrics and Device Intelligence Demo",
      url: "https://docs.sardine.ai/demo",
      type: "large",
    },
    {
      img: lines,
      title: "Behavior Biometrics and Device Intelligence SDK",
      url: "https://docs.sardine.ai/device_index/",
      type: "small",
    },
    {
      img: lines,
      title: "Real-time Risk Check for Debit/Credit Cards",
      url: "https://docs.sardine.ai/api/reference/operation/v1-customers/",
      type: "small",
    },
    {
      img: lines,
      title: "Document Verification and KYC",
      url: "https://docs.sardine.ai/products_solutions/idverification/",
      type: "small",
    },
    {
      img: lines,
      title: "Sanctions, PEP, Adverse Media ",
      url: "https://docs.sardine.ai/api/reference/operation/v1-customers/",
      type: "small",
    },
  ];
  return (
    <DevelopersCardsSectionWrapper className="section-wrapper relative z-10">
      <div className="wrapper-parent layout-width relative flex flex-col flex-nowrap justify-start w-full pt-4">
        <div className="flex flex-col justify-between w-full">
          <p className="font-Inter font-semibold text-3xl text-blue-darken mb-10 md:text-center md:text-2xl">
            Quick Links
          </p>
          <div className="flex flex-row justify-between flex-wrap -mx-4 sm:flex-col">
            <div className="flex flex-row justify-between mx-auto sm:flex-col">
              <Card
                img={cardsItems[0].img}
                title={cardsItems[0].title}
                url={cardsItems[0].url}
                type={cardsItems[0].type}
              />
              <Card
                img={cardsItems[1].img}
                title={cardsItems[1].title}
                url={cardsItems[1].url}
                type={cardsItems[1].type}
              />
            </div>
            <div className="flex flex-row justify-between mx-auto sm:flex-col">
              <div className="flex flex-col justify-between">
                <Card
                  img={cardsItems[2].img}
                  title={cardsItems[2].title}
                  url={cardsItems[2].url}
                  type={cardsItems[2].type}
                />
                <Card
                  img={cardsItems[3].img}
                  title={cardsItems[3].title}
                  url={cardsItems[3].url}
                  type={cardsItems[3].type}
                />
              </div>
              <div className="flex flex-col justify-between">
                <Card
                  img={cardsItems[4].img}
                  title={cardsItems[4].title}
                  url={cardsItems[4].url}
                  type={cardsItems[4].type}
                />
                <Card
                  img={cardsItems[5].img}
                  title={cardsItems[5].title}
                  url={cardsItems[5].url}
                  type={cardsItems[5].type}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DevelopersCardsSectionWrapper>
  );
};

export default DevelopersCardsSection;
